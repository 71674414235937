import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { IonButton, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonRow } from '@ionic/react';
import { arrowBack, arrowForward, checkmark, clipboard } from 'ionicons/icons';
import UserDashboardSelects from './UserDashboardSelects';


interface ReturnCarProps {
  control:any
  setValue:any
  closing_fuel_level:string
  element:any
  stateId:string
}

const ReturnCar: React.FC<ReturnCarProps>=({control,setValue,closing_fuel_level,element, stateId}) => {

  const isFullEndArray :any = ['outline','outline','outline','outline'];
  const [isFullEnd, setIsFullEnd]= useState(isFullEndArray)
  const [finalParkNumber, setFinalParkNumber]= useState(element.parking_number)

  function colorButtonFinal(bIndex: number): void 
  {
    const isfullEndCopy = {...isFullEnd}
    for (let _i = 0; _i <= bIndex; _i++)
    {
      isfullEndCopy[_i] = 'solid';
    }

    if (bIndex < 3)
    {
      for (let _j = bIndex+1; _j <= 3; _j++)
      {
        isfullEndCopy[_j] = 'outline' ;
      }
    }
    setIsFullEnd(isfullEndCopy)
    setValue("closing_fuel_level", (bIndex+1).toString());
  }

  useEffect(() => {
    const isFullEndArrayCopy = [...isFullEnd]

    for (let _i = 0; _i <= 3; _i++)
    {
      // populate starting fuel level
      if (_i < Number(closing_fuel_level))
        isFullEndArrayCopy[_i] = 'solid';
      else
        isFullEndArrayCopy[_i] = 'outline';
    }

    setIsFullEnd(isFullEndArrayCopy)

console.log("PArking id: ",element.location_return);

  }, []);


  return (
    <div>
        <br/>
        <div className='luogo-di'>
        {(stateId == '4' || stateId == '9') &&
          <h1 > Dati finali </h1>
        }
        {(stateId != '4' && stateId != '9') &&
          <h1 > Conferma dati finali </h1>
        }
        </div>
        <br/>
        <Controller
            name='parking_id'
            control={control}
            rules={{
              required: true,
              }}
            render={({ field: { onChange,value} }) => (              
              <>
              {element.location_return && element.location_return != null ?
                <UserDashboardSelects labelName={'Parcheggio'} value={element.location_return} endpoint={'get-parkings'} onChange={onChange}></UserDashboardSelects>
                :
                <UserDashboardSelects labelName={'Parcheggio'} endpoint={'get-parkings'} onChange={onChange}></UserDashboardSelects>
              }
              </>
              )}
          />

      <Controller
            name='parking_number'
            control={control}
            render={({ field: { onChange} }) => (
              <IonItem className='ion-item-text-style'>
                  <IonInput onIonChange={(e:any) => {onChange(e.detail.value); setFinalParkNumber(e.detail.value)}} aria-label="Light input" value={finalParkNumber} color="light" label="Numero parcheggio" labelPlacement="floating"  placeholder="0"></IonInput>
              </IonItem>
                )}
          />

    <Controller
            name='closing_km'
            control={control}
            render={({ field: { onChange} }) => (
              <IonItem className='ion-item-text-style'>
                {element.closing_km ? 
                  <IonInput onIonChange={onChange}  aria-label="Light input" value={element.closing_km} color="light"  type='number' label="Valore contachilometri (KM)" labelPlacement="floating"  placeholder="0"></IonInput>
                  :
                  <IonInput onIonChange={onChange}  aria-label="Light input" color="light"  type='number' label="Valore contachilometri (KM)" labelPlacement="floating"  placeholder="0"></IonInput>
                }
              </IonItem>
                )}
          />

    <div>
    <IonGrid>
      <IonRow>
        <IonCol size='4'>
          <IonLabel>Serbatoio</IonLabel>
        </IonCol>
        <IonCol size='1' c1osil- className='fuel-bits'>
        <IonButton color='success' fill={isFullEnd[0]}  onClick={()=> colorButtonFinal(0)}></IonButton>
        </IonCol>
        <IonCol size='1' className='fuel-bits'>
        <IonButton color='success' fill={isFullEnd[1]}  onClick={()=> colorButtonFinal(1)}></IonButton>
        </IonCol>
        <IonCol size='1' className='fuel-bits'>
        <IonButton color='success' fill={isFullEnd[2]}  onClick={()=> colorButtonFinal(2)}></IonButton>
        </IonCol>
        <IonCol size='1' className='fuel-bits'>
        <IonButton color='success' fill={isFullEnd[3]}  onClick={()=> colorButtonFinal(3)}></IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
    </div>
    <Controller
            name="closing_fuel_level"
            control={control}
            render={({ field: { onChange,value} }) => (
              <IonInput onIonChange={onChange} value={value} className='hidden_input'
              />
            )}
          />
    <Controller
            name='closing_info_1'
            control={control}
            render={({ field: { onChange} })=> (
              <IonItem className='ion-item-text-style' lines='none'>
                  <IonInput  onIonChange={onChange} value={element.closing_info_1?element.closing_info_1:null} aria-label="Light input" color="light"  type='text' label="Note conclusive" labelPlacement="floating"></IonInput>
              </IonItem>
                )}
          />
 </div>  
  );
};

export default ReturnCar;
