import {IonContent, IonFab, IonFabButton, IonIcon, IonItem, IonLabel, IonPage, IonRefresher, IonRefresherContent, RefresherEventDetail, useIonRouter } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { add, arrowForward } from 'ionicons/icons'

import './UserRequest.css'
import axios from 'axios';
import moment from 'moment/moment.js';
import 'moment/locale/it'; // Import Italian locale
import UserDashboardMenu from '../../components/UserDashboard/UserDashboardMenu';
import UserDashboardHeader from '../../components/UserDashboard/UserDashboardHeader';
import { Route } from 'react-router';
import UserRequestDetail from '../UserRequestDetail/UserRequestDetail';

const UserRequest: React.FC = () => {
  const [requests, setRequests] = useState<any[]>([])
  const [car, setCar] = useState<any[]>([])
  const request_states= [
      {"id":"0","name_it":"in attesa","name_en":"waiting",bg: 'orange-bg', bg1: 'orange-bg'},
      {"id":"1","name_it":"richiesta avallata dal responsabile","name_en":"request accepted by the person in charge",bg:'green-bg',bg1:'green-bg'},
      {"id":"2","name_it":"richiesta rifiutata dal responsabile","name_en":"request rejected by the person in charge",bg:'gray-bg',bg1:'gray-bg'},
      {"id":"3","name_it":"veicolo in uso","name_en":"rental started",bg:'red-bg', bg1:'red-bg'},
      {"id":"4","name_it":"veicolo restituito","name_en":"rental finished",bg:'green-bg', bg1:'green-bg'},
      {"id":"5","name_it":"problema","name_en":"problem", bg: 'red-bg', bg1: 'red-bg' },
      {"id":"6","name_it":"veicolo assegnato","name_en":"Car assigned",bg:'green-bg', bg1:'green-bg'},
      {"id":"7","name_it":"richiesta rifiutata dall'amministratore","name_en":"request rejected by the administrator",bg:'gray-bg',bg1:'gray-bg'},
      {"id":"8","name_it":"chiavi ritirate dall'utente","name_en":"keys given to the user",bg:'yellow-bg',bg1:'yellow-bg'},
      {"id":"9","name_it":"chiavi restituite","name_en":"keys returned",bg:'azure-bg',bg1:'azure-bg'},
      {"id":"10","name_it":"valutazione richiesta estensione","name_en":"extension request pending",bg:'yellow-bg',bg1:'yellow-bg'},
      {"id":"11","name_it":"richiesta estensione accettata","name_en":"extension request accepted",bg:'green-bg',bg1:'green-bg'},
      {"id":"12","name_it":"richiesta estensione rifiutata","name_en":"extension request rejected",bg:'red-bg',bg1:'red-bg'},
  ]

  // Use the 'useEffect' hook to perform side effects when the component mounts
  useEffect(() => {
   
    // Call the 'fetchData' function when the component mounts (empty dependency array)
    fetchData();
  }, []); // Empty dependency array ensures this effect runs only once, when the component mounts

  // Define an asynchronous function 'fetchData'
    const fetchData = async () => {
      // Send an HTTP GET request to the specified URL
      const response = await axios.post("https://bflotta.arternative-lab.it/api/app2/my-requests",{'token':localStorage.getItem('token')})
      // Update the 'data' state with the data from the API response
      setRequests(response.data);
    };

    const formatDate = (sqlDate: any) =>
    {
      moment.locale('it'); // Set locale to Italian
      return moment(sqlDate,"YYYY-MM-DD HH:mm:ss", true).format("D MMMM YYYY, HH:mm"); 
    }

    const user_pages: Array<object>= [
      { title: 'Nuova richiesta',component:'/user-dashboard'},
      { title: 'Le mie richieste', component: '/user-request'},
      { title: 'Guide'        , component: '/user-guide'},
    ];

    function handleRefresh(event: CustomEvent<RefresherEventDetail>) 
    {
      setTimeout(() => {
        // Any calls to load data go here
        event.detail.complete();
      }, 2000);
    }


  const navigation = useIonRouter();

  function openRequest(el: any): void 
  {
    setCar(el.car)
    localStorage.setItem('currentRequest', JSON.stringify(el)); // store it to pick it up in the next view
    navigation.push('/user-request-details/'+el.id+'/'+el.rental_request_state_id,'root','replace')
  }

  return (
  
    <IonContent className='requestBg' >   
        <UserDashboardMenu title={'Menu'} menuItems={user_pages} ></UserDashboardMenu>
      <IonPage id="main-content">
          <UserDashboardHeader name={'RICHIESTE'} refreshButton={true}></UserDashboardHeader>
          <IonContent>
          {
          requests.map((el)=>(
            <IonItem button  key={el.id} className={request_states[el.rental_request_state_id].bg} onClick={() => openRequest(el)} lines="none">
              <IonLabel className='req-label'>
                <h2><b>{el.details.toUpperCase()}</b></h2>
                <br/>
                <h3>{formatDate(el.date_start)} &nbsp;&nbsp; <IonIcon icon={arrowForward} /> &nbsp;&nbsp; {formatDate(el.date_end)}</h3>
                <h3 className="text-right"><i>{request_states[el.rental_request_state_id].name_it}</i></h3>
              </IonLabel>
            </IonItem>
          ))
        }
  <IonFab slot="fixed" vertical="bottom" horizontal="end">
      <IonFabButton href='/user-dashboard'>
        <IonIcon icon={add}></IonIcon>
      </IonFabButton>
    </IonFab>
    <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
          </IonContent>
          

        </IonPage>
  </IonContent>
  )
};

export default UserRequest;
