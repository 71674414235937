import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonPage, IonRouterLink } from '@ionic/react';
import React, { useEffect } from 'react';

import './UserGuideDetail.css'
import axios from 'axios';
import UserDashboardMenu from '../../components/UserDashboard/UserDashboardMenu';
import UserDashboardHeader from '../../components/UserDashboard/UserDashboardHeader';
const UserGuide: React.FC = () => {
  useEffect(() => {
    axios.post("https://bflotta.arternative-lab.it/api/app2/guides-emergency",{'token':localStorage.getItem('token')})
    .then((response) => {
        const element: HTMLElement =document.getElementById("text") as HTMLElement
        element.innerHTML = response.data.details;
    })
    .catch((error) => {
      console.log('error')
    })
 });
 const user_pages: Array<object>= [
  { title: 'Nuova richiesta',component:'/user-dashboard'},
  { title: 'Le mie richieste', component: '/user-request'},
  { title: 'Guide'        , component: '/user-guide'},
];

  return (
  
    <IonContent className='dark-content'>    
    <UserDashboardMenu title={'Menu'} menuItems={user_pages} ></UserDashboardMenu>
  <IonPage id="main-content">
      <UserDashboardHeader name={'GUIDE'}></UserDashboardHeader>
    <IonContent >
     <IonCardHeader color="danger" >
      <IonCardSubtitle className='color-em'>EMERGENZA</IonCardSubtitle>
     </IonCardHeader>
     <IonCardContent className='dark-content'>
        <div className="small-padding" id='text' >
          </div>
     </IonCardContent>

        </IonContent>
      </IonPage>
  </IonContent> 
  )
};

export default UserGuide;
