import React, { useEffect, useState } from 'react';
import './UserDashboardMenu.css';
import { IonContent, IonHeader, IonItem, IonLabel, IonList, IonMenu,IonTitle, IonToolbar, useIonRouter } from '@ionic/react';
interface MenuProps {
  title: string;
  menuItems?:any
}

const UserDashboardMenu: React.FC<MenuProps> = ({ title, menuItems }) => {
  const [menu, setMenu] = useState<any[]>([])


  const manager_pages = [
   {title:'Richieste da valutare', component: '/manager-dashboard'},
   {title:'Richieste passate', component: '/manager-past-requests'},
   { title: 'Le mie richieste',component:'/user-request'},
   { title: 'Nuova richiesta', component: '/user-dashboard'},
   { title: 'Guide'        , component: '/user-guide'},
 ];

 const user_pages: Array<object>= [
  { title: 'Nuova richiesta',component:'/user-dashboard'},
  { title: 'Le mie richieste', component: '/user-request'},
  { title: 'Guide'        , component: '/user-guide'},
];

  const navigation = useIonRouter();
  const navigate = (data:string) => {
    navigation.push(data,'root','replace')
    location.reload()
    }

const goEmergenza = () => {
      navigation.push('user-guide-detail','root','replace')
      location.reload()
      }
  const logOut =()=>{
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    navigation.push('/','root','replace')
    location.reload()
  }
  const checkUser =()=>{
    const user = localStorage.getItem('user')
    let menuItems: { title: string; component: string; }[] =[]
    if(user){
      const userArray= JSON.parse(user);
      const userTypeId = userArray.user_type_id
      if(userTypeId==4){
         menuItems=[
          {title:'Richieste da valutare', component: '/manager-dashboard'},
          {title:'Richieste passate', component: '/manager-past-requests'},
          { title: 'Le mie richieste',component:'/user-request'},
          { title: 'Nuova richiesta', component: '/user-dashboard'},
          { title: 'Guide'        , component: '/user-guide'},
        ]
      }
      else if (userTypeId==2){
         menuItems= [
          { title: 'Nuova richiesta',component:'/user-dashboard'},
          { title: 'Le mie richieste', component: '/user-request'},
          { title: 'Guide'        , component: '/user-guide'},
        ]
      }
    }
    return menuItems
  }
  useEffect(() => {
    const menu = checkUser()
    setMenu(menu)
  },[])
  return (
    <IonMenu contentId="main-content" side="end"  className='card-title'>
    <IonHeader>
      <IonToolbar>
        <IonTitle>{title}</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent className="ion-padding">
    <IonList className='list-background'>
          {
          menu.map((el:any,index:any)=>
            <IonItem className="menu-item" key={index} routerLink={el.component} routerDirection='forward' onClick={()=> navigate(el.component) }>
            <IonLabel>{el.title}</IonLabel>
          </IonItem>
        
          )
        }
         <IonItem className="menu-item"  routerDirection='forward' onClick={()=> logOut()}>
            <IonLabel>Log Out</IonLabel>
          </IonItem>
          <IonItem className="menu-item"  routerDirection='forward' onClick={()=> goEmergenza()}>
            <IonLabel className='error-style'>!! EMERGENZA !!</IonLabel>
          </IonItem>
    </IonList>
      </IonContent>
  </IonMenu>

  );
};

export default UserDashboardMenu;
