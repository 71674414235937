import React from 'react';
import './UserDashboardHeader.css';
import { IonButton, IonButtons, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonIcon, IonMenu, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { refresh } from 'ionicons/icons';

interface HeaderProps {
  name: string;
  refreshButton?:boolean;
}

const UserDashboardHeader: React.FC<HeaderProps> = ({ name,refreshButton }) => {
  function loadPage(event:any): void {
    location.reload()
  }

  return (
  <IonHeader>
    <IonToolbar className='headerToolbar'>
      <IonButtons>
        <IonMenuButton className="head-buttons">
            <img src="assets/imgs/logo-bonatti-cut.png" className='ion-title-logo' />
        </IonMenuButton>
      </IonButtons>
      <IonTitle  className="ion-text-center">{name}</IonTitle>
      {refreshButton===true? (<IonButton fill='clear' color='dark' className='head-style' onClick={loadPage}>
            <IonIcon  icon={refresh}  size='large' />
        </IonButton>):( <></> )
      }
    </IonToolbar>
  </IonHeader>
  );
};

export default UserDashboardHeader;
