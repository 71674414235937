import React, { useEffect, useState } from 'react';
import './PicUpCar.css'
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { IonButton, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonRow, IonTextarea } from '@ionic/react';
import { arrowBack, arrowForward, checkmark, clipboard } from 'ionicons/icons';
import UserDashboardTextbox from './UserDashboardTextbox';
interface Car {
  make: string
  model:string
  reg_number:string
  fuel_level:string
}
interface PicUpFormProps {
  stateId:string
  control:any
  setValue:any
  starting_km:string
  starting_info_1:string
  isToPickUp:boolean
  car:Car;
  starting_fuel_level:string
 }

const PicUpCar: React.FC<PicUpFormProps> = ({stateId, control,starting_km,starting_info_1,car,isToPickUp,setValue, starting_fuel_level}) => {

  const isFullArray :any = ['outline','outline','outline','outline'];
  const [isFull, setIsFull]= useState(isFullArray)
  const [startingFuelLevel, setStartingFuelLevel] = useState(starting_fuel_level)
  const [startingKm, setStartingKm] = useState(starting_km)
  const [startingInfo, setStartingInfo] = useState(starting_info_1)

  useEffect(() => {
    if(car)
    {
      const isFullArrayCopy = [...isFull]
      const fuelValueToUse = (Number(startingFuelLevel)> 0 ? Number(startingFuelLevel)-1 : Number(car.fuel_level)); // -1 as i need to adapt the 2 values
      for (let _i = 0; _i <= 3; _i++)
      {
        // populate starting fuel level
          if (_i <= fuelValueToUse)
            isFullArrayCopy[_i] = 'solid';
          else
            isFullArrayCopy[_i] = 'outline';
      }
      setIsFull(isFullArrayCopy);
    }
  }, []);

  function handleKmChange(e:any)
  {
    setStartingKm(e.target.value);
    setValue("starting_km", e.target.value);
  }

  function colorButton(bIndex: number): void 
  {
    const isfullCopy = {...isFull}
    for (let _i = 0; _i <= bIndex; _i++)
    {
      isfullCopy[_i] = 'solid';
    }

    if (bIndex < 3)
    {
      for (let _j = bIndex+1; _j <= 3; _j++)
      {
        isfullCopy[_j] = 'outline' ;
      }
    }

    setIsFull(isfullCopy)
    setStartingFuelLevel((bIndex+1).toString())
    setValue("starting_fuel_level", (bIndex+1).toString());
  }


  function handleTextChange(e:any)
  {
    console.log(e.target)
    setStartingInfo(e.target.value)
    setValue("starting_info_1", e.target.value);
  }

  return (
    <div>
        <br/>
        <div className='luogo-di'>
        {(stateId == '4' || stateId == '9') &&
          <h1 > Dati iniziali </h1>
        }
        {(stateId != '4' && stateId != '9') &&
          <h1 > Conferma dati iniziali </h1>
        }
        </div>
        <br/>
        <Controller
          name='starting_km'
          control={control}
          render={({ field: { onChange} }) => (
            <IonItem className='ion-item-text-style' lines='none'>
                <IonInput value={startingKm} onIonChange={handleKmChange} aria-label="Light input" color="light"  type='number' label="Valore contachilometri (KM)" labelPlacement="floating"  placeholder=""></IonInput>
            </IonItem>
          )}
        />

      <div>
          <IonGrid>
            <IonRow>
              <IonCol size='4'>
                <IonLabel>Serbatoio</IonLabel>
              </IonCol>
              <IonCol size='1' c1osil- className='fuel-bits'>
              <IonButton color='success' fill={isFull[0]}  onClick={()=> colorButton(0)}></IonButton>
              </IonCol>
              <IonCol size='1' className='fuel-bits'>
              <IonButton color='success' fill={isFull[1]}  onClick={()=> colorButton(1)}></IonButton>
              </IonCol>
              <IonCol size='1' className='fuel-bits'>
              <IonButton color='success' fill={isFull[2]}  onClick={()=> colorButton(2)}></IonButton>
              </IonCol>
              <IonCol size='1' className='fuel-bits'>
              <IonButton color='success' fill={isFull[3]}  onClick={()=> colorButton(3)}></IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
          <Controller
            name="starting_fuel_level"
            control={control}
            render={({ field: { onChange,value} }) => (
              <IonInput onIonChange={onChange} value={value} className='hidden_input'
              />
            )}
          />
        </div>
        <Controller
          name="starting_info_1"
          control={control}
           render={({ field: { onChange} }) => (
            <>
              <p>Note iniziali</p>
              <UserDashboardTextbox onChange={handleTextChange} disabled={false} value={startingInfo} ></UserDashboardTextbox>
            </>
          )}
         />
         <br/>
    </div>  
  );
};

export default PicUpCar;
