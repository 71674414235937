import React, { useEffect, useState } from 'react';
import './FormUserRequestDetails.css';
import axios from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import { IonButton, IonIcon, useIonAlert, useIonLoading, useIonRouter } from '@ionic/react';
import { arrowBack, arrowForward, checkmark, clipboard } from 'ionicons/icons';
import StaticDetails from './StaticDetails';
import ReturnCar from './ReturnCar';
import PicUpCar from './PicUpCar';
import ExtandRenatal from './ExtandRenatal';
import { Toast } from '@capacitor/toast';
interface Car {
  make: string
  model:string
  reg_number:string
  fuel_level:string
  km:string
}
interface FormProps {
 stateId:string
 data_start:string
 data_end:string
 details:string
 manager_id:string
 car: Car
 element:any
 parking_number:string
 starting_info_1:string
 starting_km:string
 starting_fuel_level:string
 closing_fuel_level:string
}

const FormUserRequestDetails: React.FC<FormProps>= ({
  stateId,data_start,data_end,details,manager_id,car,element, parking_number,starting_info_1,starting_km,starting_fuel_level,closing_fuel_level
}) => {
  const { control, handleSubmit,setValue,formState:{isSubmitting, isDirty, isValid }
} = useForm({ mode: "onChange" })

const methods = useForm();
const navigation = useIonRouter();

const [showIonLoading, dismissIonLoading] = useIonLoading();
const [presentAlert] = useIonAlert();

const [isToReturn, setisToReturn] = useState(false);
const [isToExtend, setisToExtend] = useState(false);
const [isToPickUp, setisToPickUp] = useState(false);
const [disableExtend, setDisableExtend] = useState(false);
const [disablePickUp, setDisablePickUp] = useState(false);
const [disableReturn, setDisableReturn] = useState(false);


function updateRequest(data:any)
{
  const  managerId=data.manager_id
  const request = Object.assign({'manager_id':managerId},{'token':localStorage.getItem('token')})
  axios.post("https://bflotta.arternative-lab.it/api/app2/account/rental-requests/" + element.id + "/change_manager",request)
  .then((response) => {
      presentAlert({
        header:'Fatto!',
        message: 'Manager modificato!',
        buttons:['CHUIDI']
      })
      navigation.push('/user-request','root','replace')
      location.reload()
  })
  .catch(async (error) => {
    await Toast.show({
      text: 'Errore nell\'invio della richiesta - controllare di essere connessi ad internet!'
      });
    });
}

function pickUpCar(data:any)
{
  if(!isToPickUp && !(Number(element.starting_fuel_level)> 0))
  {
    element.starting_km = (Number(starting_km) == 0 ? car.km : starting_km);
    element.starting_fuel_level = car?.fuel_level
    setisToPickUp(true)
    setDisableExtend(true)
    setDisableReturn(true)
    return
  }
  else
  {
    presentAlert({
      header:'Conferma!',
      message: "confermi di voler salvare le informazioni?", //iniziare il noleggio?",
      cssClass:'custom-alert',
      buttons:[
        {
          text: 'No',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          },
        },
        {
          text: 'Si',
          role: 'confirm',
          handler: () => {
            pickUpCarInServer(data)
          },
        },
      ]
    })
  }
}

function returnCar(data:any)
{
  if(!isToReturn)
  {
    setisToReturn(true)
    setDisableExtend(true)
    setDisablePickUp(true)
    return
  }
  else
  {
    presentAlert({
      header:'Conferma!',
      message: 'confermi di voler riconsegnare il veicolo?',
      cssClass:'custom-alert',
      buttons:[
        {
          text: 'No',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          },
        },
        {
          text: 'Si',
          role: 'confirm',
          handler: () => {
            returnCarInServer(data)
          },
        },
      ]
    })
  }
}

function extendRental(data:any)
{
  if(!isToExtend)
  {
    setisToExtend(true)
    setDisablePickUp(true)
    setDisableReturn(true)
    return
  }
  else
  {
    console.log("R2")
    if (!data.extend_date_end)
    {
      presentAlert({
        header:'Errore!',
        message: 'Inserire la data prevista per la fine della richiesta',
      })
      return;
    }

    if (!data.details_extend)
    {
      presentAlert({
        header:'Errore!',
        message: 'Inserire la motivazione per l\'estensione della richiesta',
      })
      return;
    }

    presentAlert({
      header:'Conferma!',
      message: 'confermi di voler riconsegnare il veicolo?',
      cssClass:'custom-alert',
      buttons:[
        {
          text: 'No',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          },
        },
        {
          text: 'Si',
          role: 'confirm',
          handler: () => {
            extendRentalInServer(data)
          },
        },
      ]
    })
  }
}


async function pickUpCarInServer(data: any) 
{
  const filteredObj = Object.fromEntries(
    Object.entries(data).filter(([key, value]) => value !== undefined)
  );

  const request = Object.assign(element,filteredObj,{'token':localStorage.getItem('token')})

  await showIonLoading();
    axios.post("https://bflotta.arternative-lab.it/api/app2/account/rental-requests/"+element.id+
    "/pickup_car",request)
    .then(async (response:any) => {
      dismissIonLoading();
      await Toast.show({
        text: 'Informazioni salvate!'
        });
      navigation.push('/user-request','root','replace')

      location.reload()
  })
    .catch(async(error) => {
      dismissIonLoading();
    await Toast.show({
      text: 'Errore nella connessione al server'
      });
    });
}


async function returnCarInServer(data:any) 
{
  const filteredObj = Object.fromEntries(
    Object.entries(data).filter(([key, value]) => value !== undefined)
  );

  const request = Object.assign(element,filteredObj,{'token':localStorage.getItem('token')})
  await showIonLoading();
  axios.post("https://bflotta.arternative-lab.it/api/app2/account/rental-requests/" + element.id + "/return_car",request)
  .then(async (response) => {
    dismissIonLoading();
    await Toast.show({
      text: 'Richiesta terminato correttamente!'
      });
    navigation.push('/user-request','root','replace')
    location.reload()
  })
  .catch(async (error) => {
    dismissIonLoading();
    await Toast.show({
      text: 'Errore nella connessione al server'
    });
  });
}


async function extendRentalInServer(data: any) 
{
  const filteredObj = Object.fromEntries(
    Object.entries(data).filter(([key, value]) => value !== undefined)
  );

  const request = Object.assign(element,filteredObj,{'token':localStorage.getItem('token')},{'v':2})
  await showIonLoading();
  axios.post("https://bflotta.arternative-lab.it/api/app2/account/rental-requests/" + element.id + "/extend_rental",request)
  .then(async (response:any) => {
    dismissIonLoading();
    if (response.data.status == "busy") // the car is busy
    {
      presentAlert({
        message: 'Impossibile estendere la richiesta,Il veicolo richeisto e\' gia\' stato prenotato a partire dal '+response.data.new_rent_date,
        buttons:['Ok']
      })
    }
    else if (response.data.status == "old_req") // the userneeds to  update the app
    {
      presentAlert({
        message: 'Impossibile estendere la richiesta',
        buttons:['Ok']
      })
    }
    else// ok, car available, rental extended
    {
      await Toast.show({
        text: 'Richiesta di estensione inoltrata correttamente!'
      });

      navigation.push('/user-request','root','replace')
      location.reload();
    }
  })
  .catch(async(error) => {
    dismissIonLoading();
    await Toast.show({
      text: 'Errore nella connessione al server'
    });
  });
}


return (
    <div>
      <FormProvider {...methods} > 
        <form id='req-details' className="ion-padding" >
            <StaticDetails 
              control ={control}
              data_start={data_start}
              data_end={data_end} 
              details={details}
              stateId ={stateId}
              manager_id ={manager_id}
              car ={car}
              parking_number = {parking_number}
            />
            {stateId =='0' &&
            <IonButton  form='req-details' 
            onClick={handleSubmit(updateRequest)} 
            className='submit-button' expand="full"  disabled={!isValid}>Modifica manager</IonButton>
            }
            {(isToPickUp || Number(starting_fuel_level) > 0 || stateId == '4' || stateId == '9' )&&
              <PicUpCar
                control ={control}
                setValue ={setValue}
                isToPickUp ={isToPickUp}
                stateId ={stateId}
                starting_km ={starting_km}
                starting_info_1 ={starting_info_1}
                starting_fuel_level={starting_fuel_level}
                car ={car}
              />              
            }
          {(stateId=='8' || stateId=='3') &&
            <div>
              <IonButton form='req-details'
                onClick={handleSubmit(pickUpCar)} 
                color="success" expand="full"  disabled={disablePickUp ||(isToPickUp && !isValid)}  >
              <IonIcon slot="start" icon={clipboard}></IonIcon>
              Aggiungi note iniziali
              </IonButton>
            </div>
          }   
                <br/>    
            {(isToReturn || Number(closing_fuel_level)>0 || stateId == '4' || stateId == '9' )&&
              <ReturnCar
                control ={control}
                setValue ={setValue}
                closing_fuel_level ={closing_fuel_level}
                element ={element}
                stateId ={stateId}
              />
            }
            {(stateId=='3' || stateId=='11' || stateId=='12') &&
              <div>
                <IonButton form='req-details'
                  onClick={handleSubmit(returnCar)} 
                  color="success" expand="full"  disabled={disableReturn || (isToReturn && !isValid)}  >
                <IonIcon slot="start" icon={checkmark}></IonIcon>
                  Restituisci veicolo
                </IonButton>
               </div>    
           }
            <br/>
            {(isToExtend && Number(stateId)!=10) &&
              <ExtandRenatal
                control ={control}
                setValue ={setValue}
                />
            } 
          
            {(stateId=='3' || stateId=='11' || stateId=='12') &&
                <div>
                  <IonButton form='req-details' 
                    onClick={handleSubmit(extendRental)} 
                    color="warning" expand="full" disabled={disableExtend}  >
                    <IonIcon slot="start" icon={arrowBack}></IonIcon>
                    <IonIcon slot="start" icon={arrowForward}></IonIcon>
                    Estendi richiesta
                  </IonButton>
                </div>
              }
  
      </form>
      </FormProvider>
    </div>

  );
};

export default FormUserRequestDetails;
