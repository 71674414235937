import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonPage, IonRouterLink } from '@ionic/react';
import React from 'react';

import './UserGuide.css'
import UserDashboardHeader from '../../components/UserDashboard/UserDashboardHeader';
import UserDashboardMenu from '../../components/UserDashboard/UserDashboardMenu';
import UserDashboardSubHeader from '../../components/UserDashboard/UserDashboardSubHeader';
const UserGuide: React.FC = () => {
  const user_pages: Array<object>= [
    { title: 'Nuova richiesta',component:'/user-dashboard'},
    { title: 'Le mie richieste', component: '/user-request'},
    { title: 'Guide'        , component: '/user-guide'},
  ];

  return (
    <IonContent className='dark-content'>    
    <UserDashboardMenu title={'Menu'} menuItems={user_pages} ></UserDashboardMenu>
  <IonPage id="main-content">
      <UserDashboardHeader name={'GUIDE'}></UserDashboardHeader>
    <IonContent >
     <IonCardHeader color="danger" >
      <IonRouterLink href='/user-guide-detail'>
      <IonCardSubtitle className='color-em'>EMERGENZA</IonCardSubtitle>
      </IonRouterLink>
     </IonCardHeader>
        </IonContent>
      </IonPage>
  </IonContent> 
  )
};

export default UserGuide;
function useIonRouter() {
  throw new Error('Function not implemented.');
}

