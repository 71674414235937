import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonFab, IonFabButton, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonList, IonPage, IonRefresher, IonRefresherContent, IonRow, RefresherEventDetail, useIonAlert, useIonRouter } from '@ionic/react';
import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import { checkmark,arrowForward, arrowBack, clipboard } from 'ionicons/icons'

import './UserRequestDetail.css'
import axios from 'axios';
import UserDashboardMenu from '../../components/UserDashboard/UserDashboardMenu';
import UserDashboardHeader from '../../components/UserDashboard/UserDashboardHeader';
import {useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import FormUserRequestDetails from '../../components/UserDashboard/FormUserRequestDetails';

const UserRequestDetail: React.FC = () => {
  const user_pages: Array<object>= [
    { title: 'Nuova richiesta',component:'/user-dashboard'},
    { title: 'Le mie richieste', component: '/user-request'},
    { title: 'Guide'        , component: '/user-guide'},
  ];
  const request_states= [
    {"id":"0","name_it":"in attesa","name_en":"waiting",bg: 'orange-bg', bg1: 'orange-bg'},
    {"id":"1","name_it":"richiesta avallata dal responsabile","name_en":"request accepted by the person in charge",bg:'green-bg',bg1:'green-bg'},
    {"id":"2","name_it":"richiesta rifiutata dal responsabile","name_en":"request rejected by the person in charge",bg:'gray-bg',bg1:'gray-bg'},
    {"id":"3","name_it":"veicolo in uso","name_en":"rental started",bg:'red-bg', bg1:'red-bg'},
    {"id":"4","name_it":"veicolo restituito","name_en":"rental finished",bg:'green-bg', bg1:'green-bg'},
    {"id":"5","name_it":"problema","name_en":"problem", bg: 'red-bg', bg1: 'red-bg' },
    {"id":"6","name_it":"veicolo assegnato","name_en":"Car assigned",bg:'green-bg', bg1:'green-bg'},
    {"id":"7","name_it":"richiesta rifiutata dall'amministratore","name_en":"request rejected by the administrator",bg:'gray-bg',bg1:'gray-bg'},
    {"id":"8","name_it":"chiavi ritirate dall'utente","name_en":"keys given to the user",bg:'yellow-bg',bg1:'yellow-bg'},
    {"id":"9","name_it":"chiavi restituite","name_en":"keys returned",bg:'azure-bg',bg1:'azure-bg'},
    {"id":"10","name_it":"valutazione richiesta estensione","name_en":"extension request pending",bg:'yellow-bg',bg1:'yellow-bg'},
    {"id":"11","name_it":"richiesta estensione accettata","name_en":"extension request accepted",bg:'green-bg',bg1:'green-bg'},
    {"id":"12","name_it":"richiesta estensione rifiutata","name_en":"extension request rejected",bg:'red-bg',bg1:'red-bg'},
  ]
  const [rentalRequest, setRentalRequest] = useState<any>(null)
  const [showLoader, setshowLoader] = useState(false);
  const { id,stateId } = useParams<{ id: string; stateId:any;fuelLevel:string  }>();
  const [parkNumber, setparkNumber]=useState(String)

      useEffect(() => {  

        const storedRequest = JSON.parse(localStorage.getItem('currentRequest')!);
        if (storedRequest) 
        {
          setRentalRequest(storedRequest);
          if (storedRequest.car)
          {
            axios.post("https://bflotta.arternative-lab.it/api/app2/get-parking-num",
              {'token':localStorage.getItem('token'), 'car_id':storedRequest.car.id,'req_id':storedRequest.id}
            ).then((response) => {
              setparkNumber(response.data.parking_number);
            });
          }
        }
      }, []); // Empty dependency array ensures this effect runs only once, when the component mounts
    
      function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
        setTimeout(() => {
          // Any calls to load data go here
          event.detail.complete();
        }, 2000);
      }

  if (rentalRequest)
  {
    return (
      <IonContent className='dark-content'>    
      <UserDashboardMenu title={'Menu'} menuItems={user_pages} ></UserDashboardMenu>
      <IonPage id="main-content">
        <UserDashboardHeader name={'DETTAGLI RICHIESTA'}></UserDashboardHeader>
        <IonContent >
        <Oval
              visible={showLoader}
              height="40"
              width="40"
              color="black"
              ariaLabel="oval-loading"
              wrapperStyle={{'margin':'auto','width':'40px'}}
              wrapperClass=""
                />
        
        {Number(stateId) != 3 &&
        <IonItem className={request_states[stateId].bg}  lines="none">
          <IonLabel className='req-label'>
            <h2  className='detail'>{rentalRequest.id} -  Richiesta veicolo</h2>
          </IonLabel>
        </IonItem> 
        }
        
        {Number(stateId) != 1 &&
        <IonItem className={request_states[stateId].bg}  lines="none">
            <IonLabel className='req-label'>
            <h2  className='detail'>{request_states[stateId].name_it.toUpperCase()}</h2>
          </IonLabel>
          </IonItem>
        }  

        <IonCardContent className='dark-content'>
          <FormUserRequestDetails 
            stateId={stateId}
            element ={rentalRequest}
            data_start={rentalRequest.date_start} 
            data_end={rentalRequest.date_end} 
            details={rentalRequest.details}
            manager_id ={rentalRequest.manager_id}
            car = {rentalRequest.car}
            parking_number = {parkNumber}
            starting_info_1 ={rentalRequest.starting_info_1}
            starting_km ={rentalRequest.starting_km > 0 ? rentalRequest.starting_km : (rentalRequest.car ? rentalRequest.car.km : '0')}
            starting_fuel_level={rentalRequest.starting_fuel_level}
            closing_fuel_level={rentalRequest.closing_fuel_level}
          />
          
          {stateId=='1' &&
    <IonItem className={request_states[stateId].bg} >
    <IonLabel className='req-label' >
      <h2 className='detail'>{request_states[stateId].name_it}</h2>
    </IonLabel>
    </IonItem>
    }
          </IonCardContent> 
          <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>         
        </IonContent>

        </IonPage>
    </IonContent>
    )
  }
  else // Render only when currentRequest is not null
  {
    return <div>Caricamento dati...</div>;
  }
}

export default UserRequestDetail;
