import React from 'react';
import './UserDashboardSubHeader.css';
import { IonCardHeader, IonCardSubtitle, IonIcon } from '@ionic/react';
import { car } from 'ionicons/icons'
interface SubHeaderProps {
  name: string;
}

const UserDashboardSubHeader: React.FC<SubHeaderProps> = ({ name }) => {
  return (
    <IonCardHeader color="danger">
      <IonCardSubtitle className='card-title'> 
        <h2><IonIcon icon={car} size='large' /> &nbsp; {name}</h2>
      </IonCardSubtitle>
    </IonCardHeader>
  );
};

export default UserDashboardSubHeader;
