import { IonButton, IonCard, IonCardHeader, IonCardTitle, IonContent, IonFab, IonFabButton, IonIcon, IonItem, IonLabel, IonPage, IonRefresher, IonRefresherContent, IonRouterOutlet, RefresherEventDetail, useIonRouter } from '@ionic/react';
import React, { useEffect, useState } from 'react';

import './ManagerPastRequest.css'
import { add } from 'ionicons/icons';
import UserDashboardHeader from '../../components/UserDashboard/UserDashboardHeader';
import UserDashboardMenu from '../../components/UserDashboard/UserDashboardMenu';
import axios from 'axios';
import moment from 'moment';


const ManagerPastRequest: React.FC = () => {
  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
      // Any calls to load data go here
      event.detail.complete();
    }, 2000);
  }
  const [requests, setRequests] = useState<any[]>([])
  const request_states= [
     {"id":"0","name_it":"in attesa","name_en":"waiting",bg: 'orange-bg', bg1: 'orange-bg'},
     {"id":"1","name_it":"richiesta avallata dal responsabile","name_en":"request accepted by the person in charge",bg:'green-bg',bg1:'green-bg'},
     {"id":"2","name_it":"richiesta rifiutata dal responsabile","name_en":"request rejected by the person in charge",bg:'gray-bg',bg1:'gray-bg'},
     {"id":"3","name_it":"veicolo in uso","name_en":"rental started",bg:'red-bg', bg1:'red-bg'},
     {"id":"4","name_it":"veicolo restituito","name_en":"rental finished",bg:'green-bg', bg1:'green-bg'},
     {"id":"5","name_it":"problema","name_en":"problem", bg: 'red-bg', bg1: 'red-bg' },
     {"id":"6","name_it":"veicolo assegnato","name_en":"Car assigned",bg:'green-bg', bg1:'green-bg'},
     {"id":"7","name_it":"richiesta rifiutata dall'amministratore","name_en":"request rejected by the administrator",bg:'gray-bg',bg1:'gray-bg'},
     {"id":"8","name_it":"chiavi ritirate dall'utente","name_en":"keys given to the user",bg:'yellow-bg',bg1:'yellow-bg'},
     {"id":"9","name_it":"chiavi restituite","name_en":"keys returned",bg:'azure-bg',bg1:'azure-bg'},
     {"id":"10","name_it":"valutazione richiesta estensione","name_en":"extension request pending",bg:'yellow-bg',bg1:'yellow-bg'},
     {"id":"11","name_it":"richiesta estensione accettata","name_en":"extension request accepted",bg:'green-bg',bg1:'green-bg'},
     {"id":"12","name_it":"richiesta estensione rifiutata","name_en":"extension request rejected",bg:'red-bg',bg1:'red-bg'},
 ]
 
   // Use the 'useEffect' hook to perform side effects when the component mounts
   useEffect(() => {
    
     // Call the 'fetchData' function when the component mounts (empty dependency array)
     fetchData();
   }, []); // Empty dependency array ensures this effect runs only once, when the component mounts
 
   // Define an asynchronous function 'fetchData'
     const fetchData = async () => {
       // Send an HTTP GET request to the specified URL
       const response = await axios.post("https://bflotta.arternative-lab.it/api/app2/manager/my_past_requests",{'token':localStorage.getItem('token')})
 
       // Update the 'data' state with the data from the API response
       setRequests(response.data);
     };
 
     const formatDate=(sqlDate: any)=>
     {
       return moment(sqlDate,"YYYY-MM-DD HH:mm:ss", true).format("D MMMM YYYY, HH:mm"); 
     }
     const navigation = useIonRouter();
     function openRequest(el: any): void {
      navigation.push('/manager-past-request-details/'+el.id+'/'+el.rental_request_state_id,'root','replace')
    }
  return (
    <IonContent  >   
    <UserDashboardMenu title={'Menu'} ></UserDashboardMenu>
  <IonPage id="main-content">
      <UserDashboardHeader name={'Richieste passate'} refreshButton={true}></UserDashboardHeader>
      <IonContent>
        {requests.length==0 &&
         <div  className="red-bg big-ht">
         Non ci sono richieste in sospeso.
         </div>
        }
      {
      requests.map((el)=>(
        <IonItem button  key={el.id} className={request_states[el.rental_request_state_id].bg} onClick={() => openRequest(el)}  >
          <IonLabel className='req-label'>
            <h5>{el.user.surname}</h5>
            <p className='content-color'>{formatDate(el.date_start)}-{'>'}{formatDate(el.date_end)}</p>
            <p className='content-color'>{el.details} </p>
            <p className="content-color text-right"><i>{request_states[el.rental_request_state_id].name_it}</i></p>
          </IonLabel>
        </IonItem>
      ))
    }
<IonFab slot="fixed" vertical="bottom" horizontal="end">
  <IonFabButton href='/user-dashboard'>
    <IonIcon icon={add}></IonIcon>
  </IonFabButton>
</IonFab>
<IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
      <IonRefresherContent></IonRefresherContent>
    </IonRefresher>
      </IonContent>
      

    </IonPage>
</IonContent>
  )
};

export default ManagerPastRequest;
