import React, { useEffect, useState } from 'react';
import './UserModalSelects.css';
import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import axios from 'axios';

interface SelectsProps {
  labelName?: string;
  endpoint:any;
  onChange:any
}

const UserModalSelects: React.FC<SelectsProps> = ({ labelName,endpoint,onChange }) => {
  const [data, setData] = useState<any[]>([])

  // Use the 'useEffect' hook to perform side effects when the component mounts
  useEffect(() => {
   
    // Call the 'fetchData' function when the component mounts (empty dependency array)
    fetchData();
  }, []); // Empty dependency array ensures this effect runs only once, when the component mounts

  // Define an asynchronous function 'fetchData'
    const fetchData = async () => {
      // Send an HTTP GET request to the specified URL
      const response = await axios.post("https://bflotta.arternative-lab.it/api/app2/"+endpoint,{'token':localStorage.getItem('token')})

      // Update the 'data' state with the data from the API response
      setData(response.data);
    };
    
  return (
    <IonItem  className='style-width-color'>
      <IonLabel position='floating'>
        {labelName}
      </IonLabel>
      <IonSelect onIonChange={onChange} > 
      {
        data.map((el) => (
          <IonSelectOption key={el.id} value={el.id}>
            {el.name}
          </IonSelectOption>
        ))
      }
      </IonSelect>
    </IonItem>
  );
};

export default UserModalSelects;
