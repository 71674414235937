import React, {  } from 'react';
import { IonApp, IonRouterOutlet, setupIonicReact, useIonRouter } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import UserDashboard from './pages/UserDashboard/UserDashboard';
import UserRequest from './pages/UserRequest/UserRequest';
import UserGuid from './pages/UserGuide/UserGuide';
import UserGuidDetail from './pages/UserGuideDetail/UserGuideDetail';
import Login from './pages/Login/Login';
import ManagerDashboard from './pages/ManagerDashboard/ManagerDashboard';
import ManagerRequestDetail from './pages/ManagerRequestDetail/ManagerRequestDetail';
import ManagerPastRequest from './pages/ManagerPastRequest/ManagerPastRequest';
import ManagerPastRequestDetail from './pages/ManagerPastRequestDetail/ManagerPastRequestDetail';
import UserRequestDetail from './pages/UserRequestDetail/UserRequestDetail';

setupIonicReact();

const App: React.FC = () => {
  window.addEventListener('popstate',(event)=>{
    console.log(location.reload())

  })
  const getToken=()=>localStorage.getItem('token')
  const getUser=()=>localStorage.getItem('user')
  const isAuthed=()=>!!getToken()
  const isManager=()=>{
    const user=getUser()
    if(user){
      const userArray = JSON.parse(user)
      const userTypeId =userArray.user_type_id 
      if(userTypeId=='4'){
        return true
      }else{
        return false
      }
    }
  }




  return (
    <IonApp>
        <IonReactRouter>
      <IonRouterOutlet>
        <Route  path="/" component={Login} />
          <Route exact path="/user-dashboard"
              render={() => {
                return isAuthed() ? <UserDashboard/> :<Redirect to='/'/>;
                }}
          />
          <Route exact path="/user-request"
            render={() => {
              return isAuthed() ? <UserRequest/> :<Redirect to='/'/>;
              }}
        />
        <Route exact path="/user-guide"
            render={() => {
              return isAuthed() ? <UserGuid/> :<Redirect to='/'/>;
              }}
        />
        <Route exact path="/user-guide-detail"
            render={() => {
              return isAuthed() ? <UserGuidDetail/> :<Redirect to='/'/>;
              }}
        />
  
         <Route exact path="/user-request-details/:id/:stateId"
            render={() => {
              return isAuthed() ? <UserRequestDetail/> :<Redirect to='/'/>;
              }}
        />
         <Route exact path="/manager-dashboard"
            render={() => {
              return isAuthed() && isManager() ? <ManagerDashboard/> :<Redirect to='/'/>;
              }}
        />
        <Route exact path="/manager-past-requests"
            render={() => {
              return isAuthed() && isManager() ? <ManagerPastRequest/> :<Redirect to='/'/>;
              }}
        />
        <Route exact path="/manager-request-details/:id/:stateId"
            render={() => {
              return isAuthed() && isManager()? <ManagerRequestDetail/> :<Redirect to='/'/>;
              }}
        />
        <Route exact path="/manager-past-request-details/:id/:stateId"
            render={() => {
              return isAuthed() && isManager()? <ManagerPastRequestDetail/> :<Redirect to='/'/>;
              }}
        />
       </IonRouterOutlet>      
    </IonReactRouter>
    </IonApp>
  );
};

export default App;
