import React, { useEffect, useState } from 'react';
import './UserDashboardSelects.css';
import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import axios from 'axios';

interface SelectsProps {
  labelName?: string;
  value?:any;
  endpoint:any
  onChange:any;
  style?:boolean
}

const UserDashboardSelects: React.FC<SelectsProps> = ({ labelName,endpoint,onChange,style,value }) => {
 
  const [data, setData] = useState<any[]>([])

  useEffect(() => {   
    fetchData();
  }, []);

  const fetchData = async () => {
    // Send an HTTP GET request to the specified URL
    const response = await axios.post("https://bflotta.arternative-lab.it/api/app2/"+endpoint,{'token':localStorage.getItem('token')})

    // Update the 'data' state with the data from the API response
    setData(response.data);
  };

  return (
    <IonItem  className='style-width' >
      <IonLabel position='stacked' className='label-color' color="light">
       {labelName}
      </IonLabel>
      <br />
      <IonSelect onIonChange={onChange} className='color-changed' >
        {endpoint === 'managers' ?
            data.map((el) => (
              <IonSelectOption key={el.id} value={el.id}>
                {el.name}
              </IonSelectOption>
            ))
          :
            data.map((el) => (
              <IonSelectOption key={el.id} value={el.id} >
                {el.name_it}
              </IonSelectOption>
            ))
        }
      </IonSelect>
    </IonItem>
  );
};

export default UserDashboardSelects;
