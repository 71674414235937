import React, { useState } from 'react';
import './UserDashboardStartDate.css';
import { IonItem, IonInput, IonPopover, IonDatetime } from '@ionic/react';

interface LabelProps {
  label?: string;
  onChange:any;
  disabled?:any;
  value?:any;
}

const UserDashboardStartDate: React.FC<LabelProps> = ({ label,onChange,disabled,value }) => {
  const [showPopoverStart, setShowPopoverStart] = useState(false);
  const [selectedDateStart, setSelectedDateStart] = useState<string>('');
  const [customInputValueStart, setCustomInputValueStart] = useState<string>('');
  const handleDateChangeStart = (event: CustomEvent) => {
    const newSelectedDate = event.detail.value;
    setShowPopoverStart(false)
    setSelectedDateStart(newSelectedDate);
    setCustomInputValueStart(newSelectedDate); // Set IonInput value when date changes
  };
  return (
<IonItem className='ion-item-text-style'>
{disabled?(
 <IonInput id="datestart" label={label} labelPlacement="floating" 
 onClick={() => setShowPopoverStart(true)}
 value={value} disabled={true}
 ></IonInput>
):(
  <>
   <IonInput id="datestart" label={label} labelPlacement="floating" 
  onClick={() => setShowPopoverStart(true)}
  value={customInputValueStart} 
  ></IonInput>
      <IonPopover 
            isOpen={showPopoverStart}
            onDidDismiss={() => setShowPopoverStart(false)}
            trigger="bottom-start" side="bottom" alignment="center" size='auto'
          >
            <IonDatetime size='cover' presentation="date-time" preferWheel={true} minuteValues='0,30'
            showDefaultButtons={true} locale="it-IT" doneText="Fatto" cancelText="Annulla"
            onIonChange={e=>{onChange(e);handleDateChangeStart(e)}}
            ></IonDatetime>
    </IonPopover>
  </>
 
)}
   

 </IonItem>
  );
};

export default UserDashboardStartDate;
