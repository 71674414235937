import React, { useEffect, useState } from 'react';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import UserDashboardStartDate from './UserDashboardStartDate';
import UserDashboardEndDate from './UserDashoardEndDate';
import UserDashboardTextbox from './UserDashboardTextbox';
import UserDashboardSelects from './UserDashboardSelects';
import { IonInput, IonGrid, IonRow, IonCol, IonLabel, IonButton } from '@ionic/react';
interface Car {
  make: string
  model:string
  reg_number:string
  fuel_level:string
}

interface StaticFormProps {
  data_start:string
  data_end:string
  details:string
  stateId:string
  manager_id:string
  car:Car;
  parking_number:string
  control:any
 }

const StaticDetails: React.FC<StaticFormProps> = ({
  data_start, data_end, details,stateId,manager_id,car,parking_number,control
}) => {
  const { handleSubmit,formState:{isSubmitting, isDirty, isValid }
} = useForm({ mode: "onChange" })


const isFullBeforeArray : any = ['outline','outline','outline','outline'];
const [isFullBefore, setIsFullBefore]= useState(isFullBeforeArray)
useEffect(() => {
  if(car){
    const isFullBeforeArrayCopy = [...isFullBefore]
    for (let _i = 0; _i <= 3; _i++){
      // populate current fuel level
          if (_i <= Number(car.fuel_level))
             isFullBeforeArrayCopy[_i] = 'solid';
           else
            isFullBeforeArrayCopy[_i] = 'outline';
            setIsFullBefore(isFullBeforeArrayCopy)
    }
  }

}, []);


  return (
  <div>
      <Controller
            name="date_start"
            control={control}
            render={({ field: { onChange} }) => (
              <UserDashboardStartDate label={'Data inizio'} onChange={onChange} disabled={true} value={data_start}></UserDashboardStartDate>
            )}
      />
      <Controller
          name="date_end"
          control={control}
          render={({ field: { onChange} }) => (
            <UserDashboardEndDate label={'Data fine'} onChange={onChange} disabled={true} value={data_end} ></UserDashboardEndDate>
          )}
         />
       <Controller
          name="details"
          control={control}
          render={({ field: { onChange} }) => (
            <>
            <p>Note</p>
            <UserDashboardTextbox onChange={onChange} disabled={true} value={details} ></UserDashboardTextbox>
            </>            
          )}
         />
      {stateId=='0' &&
          <Controller
          name='manager_id'
          control={control}
          rules={{
            required: true,
            }}
          render={({ field: { onChange} }) => (
            <UserDashboardSelects labelName={'Manager'} endpoint={'managers'} onChange={onChange} value={manager_id}></UserDashboardSelects>            )}
          />
       }

      {(stateId=='3' || stateId=='6'|| stateId=='8'||(Number(stateId)>=10 && Number(stateId)<=13)) &&
         <div>
             <br/>
              <div className='luogo-di'>
                <h1 > Luogo di ritiro </h1>
              </div>
              <br/>
              <Controller
              name="parking_number"
              control={control}
              render={({ field: { onChange} }) => (
                <>
              <p className='desqription'>Numero parcheggio</p>
              <div className="ion-padding-start par-num'">
                <IonInput onChange={onChange} className='par-num' disabled={true}  value={parking_number}></IonInput>
              </div>
              </>
            
          )}
         />
         <div>
         <br/>
         <div className='luogo-di'>
              <h1 > Dettagli veicolo assegnato </h1>
              </div>
         </div>
         <br/>
         {car &&
            <div className="text-left">
              {car.make} {car.model}, Targa {car.reg_number}
           </div>
         }
         
        <div>
          <IonGrid>
            <IonRow>
              <IonCol size='4'>
                <IonLabel>Carburante</IonLabel>
              </IonCol>
              <IonCol size='1' c1osil- className='fuel-bits'>
              <IonButton color='success' fill={isFullBefore[0]}></IonButton>
              </IonCol>
              <IonCol size='1' className='fuel-bits'>
              <IonButton color='success' fill={isFullBefore[1]} ></IonButton>
              </IonCol>
              <IonCol size='1' className='fuel-bits'>
              <IonButton color='success' fill={isFullBefore[2]}></IonButton>
              </IonCol>
              <IonCol size='1' className='fuel-bits'>
              <IonButton color='success' fill={isFullBefore[3]}></IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
        <hr className='hr-style'/>
        <br></br>
         </div>
         }
  </div>

  );
};

export default StaticDetails;
