import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { IonButton, IonIcon } from '@ionic/react';
import { arrowBack, arrowForward, checkmark, clipboard } from 'ionicons/icons';
import UserDashboardTextbox from './UserDashboardTextbox';
import UserDashboardEndDate from './UserDashoardEndDate';
interface ExtandCarProps {
  control:any
  setValue:any
 }

const ExtandRenatal: React.FC<ExtandCarProps>=({control,setValue}) => {

  return (
    <div>
      <Controller
      name="extend_date_end"
      control={control}
      rules={{
        required: true,
        }}
      render={({ field: { onChange} }) => (
        <UserDashboardEndDate label={'Nuova data fine richiesta'} onChange={onChange}></UserDashboardEndDate>
      )}
      />
    <Controller
        name="details_extend"
        control={control}
        rules={{
          required: true,
          }}
        render={({ field: { onChange} }) => (
          <UserDashboardTextbox label={"Motivo dell'estensione della richiesta"} onChange={onChange} ></UserDashboardTextbox>
        )}
        />
    </div>
  );
};

export default ExtandRenatal;
