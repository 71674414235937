import React, { useState } from 'react';
import './UserDashboardEndDate.css';
import { IonItem, IonInput, IonPopover, IonDatetime } from '@ionic/react';

interface LabelProps {
  label: string;
  onChange:any;
  disabled?:any;
  value?:any;
}

const UserDashboardEndDate: React.FC<LabelProps> = ({ label,onChange,disabled,value }) => {
  const [showPopoverEnd, setShowPopoverEnd] = useState(false);
  const [selectedDateEnd, setSelectedDateEnd] = useState<string>('');
  const [customInputValueEnd, setCustomInputValueEnd] = useState<string>('');
  const handleDateChangeEnd = (event: CustomEvent) => {
    const newSelectedDate = event.detail.value;
    setShowPopoverEnd(false)
    setSelectedDateEnd(newSelectedDate);
    setCustomInputValueEnd(newSelectedDate); // Set IonInput value when date changes
  };
  return (
    <IonItem className='ion-item-text-style'>
      {disabled?(
         <IonInput id="dateend" label={label} labelPlacement="floating"  
         onClick={() => setShowPopoverEnd(true)}
         value={value} disabled={disabled}
         ></IonInput>
      ):(
        <>
        <IonInput id="dateend" label={label} labelPlacement="floating"  
        onClick={() => setShowPopoverEnd(true)}
        value={customInputValueEnd}
        ></IonInput>
          <IonPopover
            isOpen={showPopoverEnd}
            onDidDismiss={() => setShowPopoverEnd(false)}
          >
             <IonDatetime size='cover' presentation="date-time" preferWheel={true} minuteValues='0,30'
            showDefaultButtons={true}  locale="it-IT" doneText="Fatto" cancelText="Annulla"
            onIonChange={e=>{onChange(e);handleDateChangeEnd(e)}}
            ></IonDatetime>
      </IonPopover>
        </>
      )}
    
     </IonItem> 
  );
};

export default UserDashboardEndDate;
