import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonDatetime, IonFab, IonFabButton, IonIcon, IonInput, IonItem, IonLabel, IonList, IonPage, IonRefresher, IonRefresherContent, IonRow, IonTitle, RefresherEventDetail, useIonAlert, useIonRouter } from '@ionic/react';
import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import { add, checkmark, refresh,close } from 'ionicons/icons'

import './ManagerPastRequestDetail.css'
import axios from 'axios';
import UserDashboardMenu from '../../components/UserDashboard/UserDashboardMenu';
import UserDashboardHeader from '../../components/UserDashboard/UserDashboardHeader';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import UserDashboardTextbox from '../../components/UserDashboard/UserDashboardTextbox';
import UserDashboardStartDate from '../../components/UserDashboard/UserDashboardStartDate';
import UserDashboardEndDate from '../../components/UserDashboard/UserDashoardEndDate';
import { Oval } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';

const ManagerPastRequestDetail: React.FC = () => {
  const [requests, setRequests] = useState<any[]>([])
  const [element, setElement]=useState<any>({})
  const [user, setUser]=useState<any>({})
  const { id,stateId } = useParams<{ id: string; stateId:any }>();
  const request_states= [
    {"id":"0","name_it":"in attesa","name_en":"waiting",bg: 'orange-bg', bg1: 'orange-bg'},
    {"id":"1","name_it":"richiesta avallata dal responsabile","name_en":"request accepted by the person in charge",bg:'green-bg',bg1:'green-bg'},
    {"id":"2","name_it":"richiesta rifiutata dal responsabile","name_en":"request rejected by the person in charge",bg:'gray-bg',bg1:'gray-bg'},
    {"id":"3","name_it":"veicolo in uso","name_en":"rental started",bg:'red-bg', bg1:'red-bg'},
    {"id":"4","name_it":"veicolo restituito","name_en":"rental finished",bg:'green-bg', bg1:'green-bg'},
    {"id":"5","name_it":"problema","name_en":"problem", bg: 'red-bg', bg1: 'red-bg' },
    {"id":"6","name_it":"veicolo assegnato","name_en":"Car assigned",bg:'green-bg', bg1:'green-bg'},
    {"id":"7","name_it":"richiesta rifiutata dall'amministratore","name_en":"request rejected by the administrator",bg:'gray-bg',bg1:'gray-bg'},
    {"id":"8","name_it":"chiavi ritirate dall'utente","name_en":"keys given to the user",bg:'yellow-bg',bg1:'yellow-bg'},
    {"id":"9","name_it":"chiavi restituite","name_en":"keys returned",bg:'azure-bg',bg1:'azure-bg'},
    {"id":"10","name_it":"valutazione richiesta estensione","name_en":"extension request pending",bg:'yellow-bg',bg1:'yellow-bg'},
    {"id":"11","name_it":"richiesta estensione accettata","name_en":"extension request accepted",bg:'green-bg',bg1:'green-bg'},
    {"id":"12","name_it":"richiesta estensione rifiutata","name_en":"extension request rejected",bg:'red-bg',bg1:'red-bg'},
]
    // Use the 'useEffect' hook to perform side effects when the component mounts
    useEffect(() => {
   
      // Call the 'fetchData' function when the component mounts (empty dependency array)
      fetchData();
    }, []); // Empty dependency array ensures this effect runs only once, when the component mounts
  
    // Define an asynchronous function 'fetchData'
      const fetchData = async () => {
        // Send an HTTP GET request to the specified URL
        const response = await axios.post("https://bflotta.arternative-lab.it/api/app2/manager/my_past_requests",{'token':localStorage.getItem('token')})
  
        // Update the 'data' state with the data from the API response
        setRequests(response.data);
        response.data.map((el:any)=>(
          el.id==id?(setElement(el),setUser(el.user)):''
        ))
      };

 
  function getStatesName(rental_request_state_id: any): React.ReactNode {
    return request_states[rental_request_state_id].name_it
  }
  const {control,  handleSubmit
} = useForm({ mode: "onChange" })
const [showLoader, setshowLoader] = useState(false);
const navigation = useIonRouter();
const [presentAlert] = useIonAlert();


  function acceptRequest(data:any){    
    presentAlert({
      header:'Conferma accettazione!',
      message: 'Sei sicuro di voler approvare la richiesta?',
      cssClass:'custom-alert',
      buttons:[
        {
          text: 'No',
          role: 'cancel',
          handler: () => {
            console.log('Alert canceled');
          },
        },
        {
          text: 'Si',
          role: 'confirm',
          handler: () => {
            acceptRequestInServer(data)
          },
        },
      ]
    })
  }

  function rejectRequest(){    
    presentAlert({
      header:'Conferma rifiuto',
      message: 'Siete sicuri di voler rifiutare questa richiesta?',
      cssClass:'custom-alert',
      buttons:[
        {
          text: 'No',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          },
        },
        {
          text: 'Si',
          role: 'confirm',
          handler: () => {
            cancelRequestInServer()
          },
        },
      ]
    })
  }
  function acceptRequestInServer(data:any) {
    setshowLoader(true)
    const request = Object.assign({'token':localStorage.getItem('token')})
    axios.post("https://bflotta.arternative-lab.it/api/app2/manager/rental-requests/"+element.id+
    "/approve",request)
    .then((response) => {
     toast.success('Approvazione riuscita!',{
      position: 'top-center'
     })
      navigation.push('/manager-dashboard','root','replace')
      location.reload()
  })
    .catch((error) => {
    setshowLoader(false)
    toast.error('Errore nella connessione al server -- err-m1', {
      position: 'top-center'
      });
    });
  }
  function cancelRequestInServer() {
    setshowLoader(true)
    const request = Object.assign({'token':localStorage.getItem('token')})
    axios.post("https://bflotta.arternative-lab.it/api/app2/manager/rental-requests/"+element.id+
    "/cancel",request)
    .then((response) => {
     toast.success('Richiesta rifiutata correttamente!',{
      position: 'top-center'
     })
      navigation.push('/manager-dashboard','root','replace')
      location.reload()
  })
    .catch((error) => {
    setshowLoader(false)
    toast.error('Errore nella connessione al server -- err-m2', {
      position: 'top-center'
      });
    });
  }


  return (
    <IonContent className='dark-content'>    
    <UserDashboardMenu title={'Menu'}></UserDashboardMenu>
  <IonPage id="main-content">
      <UserDashboardHeader name={'RICHIESTE'}></UserDashboardHeader>
    <IonContent >
    <Oval
						visible={showLoader}
						height="40"
						width="40"
						color="black"
						ariaLabel="oval-loading"
						wrapperStyle={{'margin':'auto','width':'40px'}}
						wrapperClass=""
  						/>

   <IonItem className={request_states[stateId].bg} lines="none">
    <IonLabel className='req-label'>
      <h2 className='detail'>{element.id} - <b>{user.surname}</b></h2>
    </IonLabel>
  </IonItem>
   
  <IonItem className={request_states[stateId].bg} lines="none">
    <IonLabel className='req-label' >
      <h2 className='detail'><b>{getStatesName(stateId)!.toLocaleString().toUpperCase()}</b></h2>
    </IonLabel>
  </IonItem>
   
  <IonCardContent className='dark-content'>
  <form id='req-details' className="ion-padding" onSubmit={handleSubmit(acceptRequest)}>
    <div className='manager_details_date'>
      <Controller
        name="date_start"
        control={control}
        render={({ field: { onChange} }) => (
          <UserDashboardStartDate label={'Data inizio'} onChange={onChange} disabled={true} value={element.date_start}></UserDashboardStartDate>
        )}
      />
      <Controller
      name="date_end"
      control={control}
      render={({ field: { onChange} }) => (
        <UserDashboardEndDate label={'Data fine'} onChange={onChange} disabled={true} value={element.date_end} ></UserDashboardEndDate>
      )}
      />
    </div>

    <Controller
      name="details"
      control={control}
      render={({ field: { onChange} }) => (
        <>
        <p className='desqription'>Note</p>
        <UserDashboardTextbox onChange={onChange} disabled={true} value={element.details} ></UserDashboardTextbox>
        </>
        
      )}
    />

          {/* {element.rental_request_state_id > 9 && */}
    {(stateId=='9' || stateId== '10') &&
      <>
        <IonRow>
          <IonTitle className='noleggio'>Richiesta di estensione noleggio</IonTitle>
        </IonRow>
        <IonRow className='ion-margin  ion-align-items-stretch color_nuova'>
          <Controller
              name="date_start"
              control={control}
            render={({ field: { onChange} }) => (
              < UserDashboardStartDate label={'Nuova data termine'} onChange={onChange} disabled={true} value={element.desired_extension_end_date} ></UserDashboardStartDate>
            )}
            />
        </IonRow>

        <Controller
          name="details"
          control={control}
          render={({ field: { onChange} }) => (
            <UserDashboardTextbox label={'Motivo della richiesta'} disabled={true} value={element.extension_reason} onChange={onChange}  ></UserDashboardTextbox>
          )}
        />
      </>
    }
  </form>

  {(stateId=='0' || stateId=='2') &&
    <div>
       <IonButton color='success'  form='req-details'  expand="full"  type="submit" >
       <IonIcon slot="start" icon={checkmark}></IonIcon>
        Accetta
        </IonButton>
        </div>
     }
     {stateId=='0' &&
     <div>
      <IonButton  color='danger' form='req-details'  expand="full"  onClick={()=> rejectRequest()} >
       <IonIcon slot="start" icon={close}></IonIcon>
       Rifuta
        </IonButton>
     </div>
     }
      
    <ToastContainer  autoClose={5000}/>
    </IonCardContent>
    </IonContent>
    </IonPage>
    </IonContent>
 
  )
};

export default ManagerPastRequestDetail;

  