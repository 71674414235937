import {IonButton, IonContent, IonFooter, IonAlert, IonIcon, IonInput, IonItem, IonLabel, IonPage, IonRow, IonText, useIonRouter } from '@ionic/react';
import React, { useState, useEffect } from 'react';

import './Login.css'
import { lockClosed,key, navigate } from 'ionicons/icons';
import { Controller, set, useForm } from 'react-hook-form';
import { ColorRing, Oval } from 'react-loader-spinner'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Toast } from '@capacitor/toast';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router'


const Login: React.FC = () => {
  const navigation = useIonRouter();
  const history = useHistory()
  const {control,  handleSubmit, formState
  } = useForm({ mode: "onChange" })

  const [showLoader, setshowLoader] = useState(false);

  
  const [showAlert, setShowAlert] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const [email, setEmail] = useState('')



  

  useEffect(() => {
    // Controlla se il token esiste in localStorage
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    if (token && user) 
    {
		console.log(user.user_type_id)
          // Se l'utente è già loggato, naviga alla dashboard appropriata
          if (user.user_type_id === 2) 
          {
			navigation.push('/user-dashboard','forward','replace')
			location.reload();
          } 
          else 
          {
			navigation.push('/manager-dashboard','forward','replace')
			location.reload();
          }
      }
  }, [navigation]);



  const login = (data:any)  => {
	setshowLoader(true)
	const username = data.username;
	const password = data.password;
	axios.post("https://bflotta.arternative-lab.it/api/app2/auth/login", {
		email: username,
		password: password
	  })
	  .then((response) => {
		setshowLoader(false)
		localStorage.setItem('token',response.data.token)
		localStorage.setItem('user',JSON.stringify(response.data.user))
	
		if(response.data.user.user_type_id==2){
			navigation.push('/user-dashboard','forward','replace')
			location.reload();
			
		}else{
			navigation.push('/manager-dashboard','forward','replace')
			location.reload();
		}
	  })
	  .catch(async (error) => {
		setshowLoader(true)
	   const errorJson=JSON.parse(error.request.response)
		await Toast.show({
			text: errorJson.message,
		  });
    });

  }
  const modifyPassword = () => {

	if (!email || email === '') 
	{
		setErrorMessage("Immetti l'indirizzo email per favore.");
		setShowAlert(true);
		return;
	}
	else 
	{
		setshowLoader(true)
  
		axios.post("https://bflotta.arternative-lab.it/api/app2/auth/changepsw", {email: email})
		.then((response) => {
			setErrorMessage(response.data.message);
			setShowAlert(true);
			return;
		})
		.catch(async (error) => {
			setErrorMessage("Errore nell'invio della richiesta "+ error);
			setShowAlert(true);
			return;
		});

		// this.api.post('auth/changepsw', user).subscribe(
		//   (response: any) => {
		// 	setLoading(false);
		// 	setErrorMessage(response.message);
		// 	setShowAlert(true);
		//   },
		//   (error: any) => {
		// 	setLoading(false);
		// 	setErrorMessage(error.message); // Assuming error.message contains the error message
		// 	setShowAlert(true);
		//   }
		// );
	  }

	}

	return (
		<IonPage className='loginPage'>
			<IonContent fullscreen>
				<div className="splash-bg">
					<div className="splash-info">
						<div className="splash-logo"></div>
						<h1> RICHIESTA VETTURE </h1>
					</div>
				</div>
				<form className="ion-padding" onSubmit={handleSubmit(login)}>
					{formState.errors.username && 
						<IonText color='danger'>
								<sub className=''>Nome utente necessario.</sub>
						</IonText>
					}
					<Controller
						name='username'
						control={control}
						rules={{
							required: true,
							}}
						render={({ field: { onChange} }) => (
							<IonItem>
								<IonLabel color={'tertiary'} position='fixed'>
									Email
								</IonLabel>
								<IonInput type="text" onIonChange={(e) => {onChange(e.detail.value); setEmail(''+e.detail.value)}} color="secondary"></IonInput>
							</IonItem> 
						)}
					/>
					{formState.errors.password && 
						<IonText color='danger'>
							<sub className=''>Password necessaria</sub>
						</IonText>
					}
					<Controller
						name='password'
						control={control}
						rules={{
							required: true,
							}}
						render={({ field: { onChange} }) => (
							<IonItem>
							<IonLabel color={'tertiary'} position='fixed'>
						Password
							</IonLabel>
							<IonInput type="password" onIonChange={onChange} color="secondary"></IonInput>
						</IonItem> 
						)}
					/>
					<IonButton color='medium '  type="submit" className="custom-button" expand="block">
						<IonIcon slot="start" icon={lockClosed}></IonIcon>
						Login
					</IonButton>
					<br />
					<IonButton color='warning'  className="custom-button" expand="block" onClick={ modifyPassword }>
						<IonIcon slot="start" icon={key}></IonIcon>
						Modifica password
					</IonButton>
				</form>
			</IonContent>

			<IonFooter>
				<IonRow>
				</IonRow>
			</IonFooter>
			<ToastContainer  autoClose={3000}/>

			<IonAlert
				isOpen={showAlert}
				onDidDismiss={() => setShowAlert(false)}
				header="Cambio password"
				message={errorMessage}
				buttons={['Ok']}
			/>
		</IonPage>
	);
};

export default Login;
