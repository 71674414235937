import React from 'react';
import './UserDashboardTextbox.css';
import { IonItem, IonTextarea } from '@ionic/react';

interface TexboxProps {
  label?: string;
  onChange:any;
  disabled?:any;
  value?:any;


}

const UserDashboardTextbox: React.FC<TexboxProps> = ({ label,onChange,disabled,value }) => {
  return (
    <IonItem lines='none' className='ion-item-text-style border-style'>
      {disabled ? 
        (
        <IonTextarea className='inputTextarea' label={label} labelPlacement="floating" onIonChange={onChange} color='light' disabled={disabled} value={value}></IonTextarea>
        ):(
          value ? 
            <IonTextarea className='inputTextarea' label={label} labelPlacement="floating" onIonChange={onChange} color='light' value={value} ></IonTextarea>
            :
            <IonTextarea className='inputTextarea' label={label} labelPlacement="floating" onIonChange={onChange} color='light' ></IonTextarea>
      )}
    </IonItem>
  );
};

export default UserDashboardTextbox;
