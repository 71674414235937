import React, { useEffect, useState } from 'react';

import {
  IonContent,
  IonButton,
  IonCardContent,
  IonPage,
  IonRouterOutlet,
  useIonRouter,
  useIonAlert
} from '@ionic/react';
import './UserDashboard.css'
import UserDashboardHeader from '../../components/UserDashboard/UserDashboardHeader';
import UserDashboardSubHeader from '../../components/UserDashboard/UserDashboardSubHeader';
import UserDashboardSelects from '../../components/UserDashboard/UserDashboardSelects';
import UserDashboardTextbox from '../../components/UserDashboard/UserDashboardTextbox';
import UserDashboardStartDate from '../../components/UserDashboard/UserDashboardStartDate';
import UserDashboardEndDate from '../../components/UserDashboard/UserDashoardEndDate';
import UserDashboardCheckbox from '../../components/UserDashboard/UserDashboardCheckbox';
import { Controller, useForm } from 'react-hook-form';
import UserDashboardMenu from '../../components/UserDashboard/UserDashboardMenu';
import { ToastContainer, toast } from 'react-toastify';
import { Oval } from 'react-loader-spinner';
import axios from 'axios';

const UserDashboard: React.FC = () => {
  const navigation = useIonRouter();

  const [secondUser, setSecondUser] = useState(false);
  const [modalData, setModalData] = useState({});
  const [showLoader, setshowLoader] = useState(false);
  const [presentAlert] = useIonAlert();
  const user_pages: Array<object>= [
    { title: 'Nuova richiesta',component:'/user-dashboard'},
    { title: 'Le mie richieste', component: '/user-request'},
    { title: 'Guide'        , component: '/user-guide'},
  ];
  const newRequest = (data:any) => {
     const request = Object.assign(data,modalData,{secondUser:secondUser},{'token':localStorage.getItem('token')})
     setshowLoader(true)
     axios.post("https://bflotta.arternative-lab.it/api/app2/send_request",request)
      .then((response) => {
        const jsonResponse=JSON.stringify(response)

          presentAlert({
            header:'Fatto!',
            message:'Richiesta inoltrata!',
            buttons:['CHUIDI']
          })
          navigation.push('user-request','root','replace')
          location.reload()
        
          console.log(response,5555)
      })
      .catch((error) => {
      setshowLoader(false)
      console.log(error,2222)
      presentAlert({
        header:'Vetture non disponibili',
        subHeader:'Nessuna vettura disponibile nel periodo richiesto!',
        message:'Cambiare date e riprovare.',
        buttons:['CHUIDI']
      })
      });
  }


  const handleModalSubmit =(modalData:any)=>{
    setSecondUser(true);
    setModalData(modalData)
  }


  const {control,  handleSubmit, formState:{isSubmitting, isDirty, isValid }
} = useForm({ mode: "onChange" })

  return (
    <IonContent className='dark-content'>    
      <UserDashboardMenu title={'Menu'} menuItems={user_pages} ></UserDashboardMenu>
      <IonPage id="main-content">
          <UserDashboardHeader name={'NUOVA RICHIESTA'}></UserDashboardHeader>
        <IonContent >
        <UserDashboardSubHeader name={'Richiedi veicolo'}></UserDashboardSubHeader>
      <IonCardContent className='dark-content'>
      <Oval
						visible={showLoader}
						height="40"
						width="40"
						color="black"
						ariaLabel="oval-loading"
						wrapperStyle={{'margin':'auto','width':'40px'}}
						wrapperClass=""
  						/>
              <ToastContainer  autoClose={3000}/>
      <form id='my-form' className="ion-padding" onSubmit={handleSubmit(newRequest)}>
          <Controller
          name="details"
          control={control}
          rules={{
            required: true,
            }}
          render={({ field: { onChange} }) => (
            <UserDashboardTextbox label={'Destinazione'} onChange={onChange} ></UserDashboardTextbox>
          )}
         />
         <Controller
          name="date_start"
          control={control}
          rules={{
            required: true,
            }}
          render={({ field: { onChange} }) => (
            <UserDashboardStartDate label={'Data inizio'} onChange={onChange}></UserDashboardStartDate>
          )}
         />
         <Controller
          name="date_end"
          control={control}
          rules={{
            required: true,
            }}
          render={({ field: { onChange} }) => (
            <UserDashboardEndDate label={'Data fine'} onChange={onChange}></UserDashboardEndDate>
          )}
         />
        <Controller
            name='manager_id'
            control={control}
            rules={{
              required: true,
              }}
            render={({ field: { onChange} }) => (
              <UserDashboardSelects labelName={'Responsabile'} endpoint={'managers'} onChange={onChange}></UserDashboardSelects>            )}
          />
       </form>
        <UserDashboardCheckbox label={'Stai richiedendo un veicolo per qualcun altro?'} onModalSubmit={handleModalSubmit}></UserDashboardCheckbox>
        <IonButton  form='my-form' className='submit-button' expand="full"  type="submit" disabled={!isDirty || !isValid} >Invia</IonButton>
      
        
      </IonCardContent>
        </IonContent>
        <ToastContainer  autoClose={3000}/>
      </IonPage>
  </IonContent> 
  )
};

export default UserDashboard;
