import React, { useRef, useState } from 'react';
import './UserDashboardCheckbox.css';
import { IonButton, IonButtons, IonCheckbox, IonContent, IonFooter, IonInput, IonItem, IonLabel, IonModal, IonToolbar } from '@ionic/react';
import { Controller, useForm } from 'react-hook-form';
import UserModalSelects from './UserModalSelects';
import { OverlayEventDetail } from '@ionic/core/components';


interface LabelProps {
  label: string;
  onModalSubmit:any
}

const UserDashboardCheckbox: React.FC<LabelProps> = ({ label,onModalSubmit }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const modal = useRef<HTMLIonModalElement>(null);
  const {control,  handleSubmit:handleSubmit2, formState:{isSubmitting, isDirty, isValid }
} = useForm({ mode: "onChange" })
  
  const confirm = (data:any) => {
    console.log('modal ', data);
  }
  function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
    if (ev.detail.role === 'confirm') {
      console.log(`Hello, ${ev.detail.data}!`)
    }
  }

 
  return (
    <IonItem className='ion-item-text-style'>
      <IonCheckbox className='checkbox_style' labelPlacement="end" onClick={() => {setIsOpen(true), setIsChecked(true)}} justify='start' checked={isChecked}>
      <IonLabel className='ion-text-wrap label-color' color='light'>{label}</IonLabel>
      </IonCheckbox>
      <IonModal className='modal-style' isOpen={isOpen}  ref={modal}  onWillDismiss={(ev) => onWillDismiss(ev)}>
      <form  className="ion-padding-height" onSubmit={handleSubmit2(onModalSubmit)}  > 
          <IonContent className="ion-padding">
            <IonItem className='modal-item'>
              <Controller
                  name='second_user_name'
                  control={control}
                  rules={{
                    required: true,
                    }}
                  render={({field:{onChange}})=>(
                    <IonInput label='Nome' labelPlacement="floating"  onIonChange={onChange} ></IonInput>
                  )}
              />
            </IonItem>
            <IonItem   >
            <Controller
                name='second_user_surname'
                control={control}
                rules={{
                  required: true,
                  }}
                render={({field:{onChange}})=>(
                  <IonInput label='Congome'  labelPlacement="floating"  onIonChange={onChange} ></IonInput>
                )}
            />
            </IonItem>
              <Controller
                name='second_user_business_unit_id'
                control={control}
                rules={{
                  required: true,
                  }}
                render={({ field: { onChange} }) => (
                  <UserModalSelects  endpoint={'business-units'} onChange={onChange} labelName='Centro di costo'></UserModalSelects>          )}
              />
          </IonContent>
          <IonFooter>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={() =>{setIsOpen(false),setIsChecked(false)}} color='danger' fill="solid">Annulla</IonButton>
              </IonButtons>
              <IonButtons slot="end">
                <IonButton strong={true} onClick={() =>{setIsOpen(false),setIsChecked(true)}}  type="submit" color='danger' fill="solid" disabled={!isDirty || !isValid}>Invia</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonFooter>
          </form>
        </IonModal> 
    </IonItem>
  );
};

export default UserDashboardCheckbox;
